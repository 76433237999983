import React, { useMemo } from 'react';
import styles from './index.module.less';
import { useTranslation } from 'next-i18next';
import { Button, FloatButton } from 'antd';
// import { useUserProvider } from '@/context/UserProvider';
import { useRouter } from 'next/router';
import { ga_logEvent } from '@/shared/ga';
import { EventName } from '@/shared/event-types';
import { imgUrl } from '@/shared/utils';

const RegisterContent = () => {
  const { t } = useTranslation('common');
  // const { onOpenLoginModal, onChangedLoginView, isLogin } = useUserProvider();
  const router = useRouter();

  const onDashboardClick = () => {
    // if (isLogin) {
    router.push(`/dashboard?alertType=1`);
    ga_logEvent(EventName.home_ADD_ACCOUNT);

    // } else {
    //   onChangedLoginView(true);
    //   onOpenLoginModal(true);
    // }
  };

  const getContent = useMemo(() => {
    return (
      <div>
        <FloatButton
          type="primary"
          className={styles.floatBtnContent}
          shape="square"
          style={{ right: 32 }}
          description={t('ADD ACCOUNT')}
          onClick={onDashboardClick}
        />

        <div className={styles.content} style={{ backgroundImage: `url(${imgUrl("https://res-gpts.pumpsoul.com/img/sign-up-icon.png", 'mid')})` }}>
          <p className={styles.title}>{t('YourUltimateInstagramActivityTracker')}</p>
          <p className={styles.desc}>{t('NeedToKnowMoreAboutSomeoneSpecial_Desc')}</p>
          <Button type="primary" className={styles.signUpBtn} onClick={onDashboardClick}>
            {t('ADD ACCOUNT')}
          </Button>
        </div>
      </div>
    );
  }, [onDashboardClick]);

  return <>{getContent}</>;
};

export default RegisterContent;
